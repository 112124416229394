@font-face {
	font-family: "Gotham Narrow";
	src: url("./Fonts/Gotham-font-family/Gotham-Narrow/GothamNarrow-Book.otf") format("opentype");
} @font-face {
	font-family: "Gotham";
	src: url("./Fonts/Gotham-font-family/Gotham/Gotham-Book.otf") format("opentype");
} @font-face {
	font-family: "Old London";
	src: url("./Fonts/old_london/Olondona.otf") format("opentype");
} @font-face {
	font-family: "Fette Fraktur";
	src: url("./Fonts/Fette-Fraktur/FetteUNZFraktur.ttf") format("truetype");
} @font-face {
	font-family: "Gotham Rounded";
	src: url("./Fonts/Gotham-font-family/Gotham-Office/GothamOffice-Bold.otf") format("opentype");
} @font-face {
	font-family: "Cambridge";
	src: url("./Fonts/Cambridge/Cambridge.otf") format("opentype");
}


:root {
	--font_gotham: 'Gotham Narrow';
	--font_gothamN: 'Gotham';
	--font_gothamR: 'Gotham Rounded';
	--font_fraktur: 'Fette Fraktur';
	--font_london: 'Old London';
	--font_cambridge: 'Cambridge';
	--color_maroon: #800000;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */ 
   font-family: source-code-pro; /* Second font is fallback font */
   line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
	font-family: var(--font_gotham), 'Adobe Garamond', 'Fira Sans' ;
	color:#442222;
}

a:hover {
  color:#ab0000;
}

h1 {
	font-family: var(--font_gothamR), 'Adobe Garamond', 'Fira Sans' ;
	/* Change link Color */ 
}

h3 {
	font-family: var(--font_gothamR), 'Adobe Garamond', 'Fira Sans' ;
	font-weight: lighter; /* Adjust to 'lighter' or 'bold' if needed */
	color: #202020;
	/* Change link Color */ 
}


p {
  font-family: var(--font_gothamR), 'Adobe Garamond', 'Fira Sans' ;
  font-weight: lighter; /* Adjust to 'lighter' or 'bold' if needed */
  color: #333333;
  /* Change link Color */ 
}



code {
  font-family: var(--font_gothamN), source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.navbar{
	background-color: #800000;
}

.nav-link{
	color: #FFFFFF;
	font-family: var(--font_gothamN), source-code-pro;
}

.navbar-brand {
    color: #FFFFFF;
    font-family: var(--font_cambridge);
    font-size: 2.5rem; /* Adjust as needed */
    font-stretch: condensed; /* Makes the font skinnier (if supported) */
}

.login-button {
    font-size: 2rem; /* Increase text size */
}

.btn {
    background-color: #700000; /* Maroon */
    color: white; /* Text color */
    border: none;
    font-family: var(--font_gotham);
    font-size: 1.05rem; /* Increase text size */
    padding: 8px 16px;
    border-radius: 10px; /* Rounded corners */
    transition: background-color 0.3s ease-in-out;
}

.btn:hover {
    background-color: #a00000; /* Darker maroon on hover */
    color: #ffffff;
}

/* Disabled button color */
.btn:disabled {
    background-color: #900000; /* Maroon */
    color: #f0f0f0; /* Light grey text */
    cursor: not-allowed; /* Shows the "not-allowed" cursor */
}

/* Disabled button on hover */
.btn:disabled:hover {
    background-color: #f00000; /* Keeps maroon when disabled */
    color: #f0f0f0; /* Light grey text */
    cursor: not-allowed;
}

.card-body {
  font-family: var(--font_gothamR), 'Adobe Garamond', 'Fira Sans' ;
  font-weight: lighter; /* Adjust to 'lighter' or 'bold' if needed */
}


/*
 <img 
                    src="/images/ArtsfestPhoto.jpg"
                    alt="Our Current ArtsFest Board!"
                    style={{
                        maxWidth: '100%',  // Limits the width to 100% of its container
                        height: 'auto',    // Maintains the aspect ratio
                        display: 'block',  // Makes the image a block element (to center it)
                        margin: '0 auto',  // Centers the image horizontally
                        marginBottom: '20px' // Adds space below the image
                    }}
                />
*/






